import { Grid } from '@mui/material';
import { NumberChartType } from '../../charts/NumberChart/NumberChart';
import { SectionTitle } from '../SectionTitle/SectionTitle';
import React, { useContext, useState } from 'react';
import { FilterProps, FilterStorageKey, getFilterCount } from '../../filters/Filter/common/filter.util';
import { t } from '../../../../types/translation/Translator';
import { BarChartContainer } from '../../../../components/Dashboard/Graphs/Containers/BarChartContainer';
import { NumberChartContainer } from '../../../../components/Dashboard/Graphs/Containers/NumberChartContainer';
import FilterModal from '../SearchBarWithFilter/FilterModal';
import { Button } from '../Button/Button';
import FilterIcon from '../../../icons/Filter/FilterIcon';
import { OrderFilter } from '../../filters/Filter/Order/OrderFilter';
import { StockLocationContext } from '../../../../context/StockLocationContext';
import { OrderTableSettingsContext } from '../../../../context/OrderTableSettingsContext';
import { TagRelationContext } from '../../../../context/TagRelationContext';
import { UserContext } from '../../../../context/UserContext';

interface OrderMetricsProps {
  color: string;
}

export default function OrderMetrics({ color }: OrderMetricsProps) {
  const { filteredStockLocations } = useContext(StockLocationContext);
  const { orderTableSettings } = useContext(OrderTableSettingsContext);
  const { tagRelations } = useContext(TagRelationContext);
  const { companyUsers } = useContext(UserContext);

  const [filterProps, setFilterProps] = useState<FilterProps>(new FilterProps(undefined, undefined));
  const [filterModalOpen, setFilterModalOpen] = useState(false);

  return (
    <>
      <FilterModal
        open={filterModalOpen}
        onClose={() => setFilterModalOpen(false)}
        filter={new OrderFilter(filteredStockLocations, orderTableSettings, tagRelations, companyUsers)}
        filterProps={filterProps}
        setFilterProps={setFilterProps}
      />

      <Grid item xs={12} mt={1}>
        <SectionTitle text={t().orderMetrics.singular.label} />
      </Grid>
      <Grid item xs={12} mb={1}>
        <Grid container>
          <Grid item>
            <Button
              startIcon={<FilterIcon />}
              text={t().filter.singular.label}
              badge={getFilterCount(filterProps)}
              onClick={() => setFilterModalOpen(true)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} display={'flex'}>
        <Grid container columnSpacing={1}>
          <Grid item lg={4} md={6} sm={12} xs={12} display={'flex'}>
            <Grid container display={'flex'}>
              <Grid item xs={12} display={'flex'}>
                <Grid container columnSpacing={1} display={'flex'}>
                  <NumberChartContainer
                    type={NumberChartType.text}
                    size={{ xs: 6, sm: 6, md: 6, lg: 6 }}
                    text={t().total.singular.label}
                    color={color}
                    filter={filterProps}
                    entity={FilterStorageKey.ORDER}
                  />
                  <NumberChartContainer
                    type={NumberChartType.text}
                    size={{ xs: 6, sm: 6, md: 6, lg: 6 }}
                    text={t().complete.singular.label}
                    color={color}
                    filter={filterProps}
                    entity={FilterStorageKey.ORDER}
                    additionalFilter='status = complete OR status = released'
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} display={'flex'}>
                <Grid container columnSpacing={1} display={'flex'}>
                  <NumberChartContainer
                    type={NumberChartType.text}
                    size={{ xs: 6, sm: 6, md: 6, lg: 6 }}
                    text={t().inbound.singular.label}
                    color={color}
                    filter={filterProps}
                    entity={FilterStorageKey.ORDER}
                    additionalFilter='type = inbound'
                  />
                  <NumberChartContainer
                    type={NumberChartType.text}
                    size={{ xs: 6, sm: 6, md: 6, lg: 6 }}
                    text={t().outbound.singular.label}
                    color={color}
                    filter={filterProps}
                    entity={FilterStorageKey.ORDER}
                    additionalFilter='type = outbound'
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={8} md={6} sm={12} display={'flex'}>
            <Grid container columnSpacing={1} display={'flex'} className='h-full'>
              <BarChartContainer
                text={t().numberOfOrders.singular.label}
                entity={FilterStorageKey.ORDER}
                color={color}
                filter={filterProps}
                className='min-h-[200px]'
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
