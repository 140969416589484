import { useApolloClient, useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { User } from '../types/user';
import { getAccessToken } from '../util/token.util';
import { UserContext, UserContextProps } from '../context/UserContext';
import { LoginScreen } from './Authentication/LoginScreen';
import MainPage from './Main/MainPage';
import VerifyEmailScreen from './Authentication/VerifyEmailScreen';
import SignUpScreen from './Authentication/SignUpScreen';
import { ForgotPasswordScreen } from './Authentication/ForgotPasswordScreen';
import { GetUsersResponse, GetUsersVariables, UserQueries } from '../graphql/user.graphql';
import { LoginWithCodeScreen } from './Authentication/LoginWithCodeScreen';
import { ENGLISH_TRANSLATION_URL, Translation, TranslationLanguage } from '../types/translation/translation';
import { TranslationContextProps, TranslationContext } from '../context/TranslationContext';

import { translationsManager } from '../types/translation/Translator';
import ActivateMicrosoftSubscriptionScreen from './Authentication/microsoft/ActivateMicrosoftSubscriptionScreen';

function App() {
  const navigate = useNavigate();

  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [companyUsers, setCompanyUsers] = useState<Map<string, User>>(new Map());

  const [translations, setTranslations] = useState<Map<string, Translation>>(new Map());
  const [loading, setLoading] = useState(true);

  const isLoggedIn = !!getAccessToken();

  const client = useApolloClient();

  const signOut = () => {
    setCurrentUser(null);
    client.clearStore();
    localStorage.clear();
    sessionStorage.clear();
    navigate('/login');
  };

  useEffect(() => {
    fetch(ENGLISH_TRANSLATION_URL, { mode: 'no-cors' })
      .then(async response => {
        const result = await response.json();
        const translation = new Translation(TranslationLanguage.en, result);
        setTranslations(new Map(translations).set(TranslationLanguage.en, translation));
        translationsManager.setTranslation(translation, { update: true });
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      });
  }, []);

  const [getUsers] = useLazyQuery<GetUsersResponse, GetUsersVariables>(UserQueries.getForCompany);

  const loadUsers = async (companyId: string, page: number): Promise<void> => {
    const result = await getUsers({ variables: { companyId, page } });
    const getUserResponse = result.data?.users;
    if (!getUserResponse) return;
    getUserResponse.data.forEach(u => companyUsers.set(u.userId, new User(u)));
    setCompanyUsers(new Map(companyUsers));
    if (getUserResponse.hasNext) return loadUsers(companyId, ++getUserResponse.page);
  };

  const refreshCompanyUsers = async (companyId: string) => {
    setCompanyUsers(new Map());
    await loadUsers(companyId, 0);
  };

  const userContext: UserContextProps = {
    currentUser,
    setCurrentUser,
    companyUsers,
    setCompanyUsers,
    refreshCompanyUsers,
    signOut,
  };

  const translationContext: TranslationContextProps = {
    translations,
    setTranslations,
  };

  let routes;
  if (!isLoggedIn) {
    routes = (
      <>
        <Route path='/activate/microsoft' element={<ActivateMicrosoftSubscriptionScreen />} />
        <Route path='/verify' element={<VerifyEmailScreen />} />
        <Route path='/login/code' element={<LoginWithCodeScreen />} />
        <Route path='/login' element={<LoginScreen />} />
        <Route path='/signup' element={<SignUpScreen />} />
        <Route path='/reset' element={<ForgotPasswordScreen />} />
        <Route path='/*' element={<Navigate to={'/login'} />} />
      </>
    );
  } else {
    routes = (
      <>
        <Route path='/*' element={<MainPage />} />
      </>
    );
  }

  return (
    <TranslationContext.Provider value={translationContext}>
      <UserContext.Provider value={userContext}>
        <Routes>{routes}</Routes>
      </UserContext.Provider>
    </TranslationContext.Provider>
  );
}

export default App;
