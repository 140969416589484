import React, { useContext, useMemo, useState } from 'react';
import { t } from '../../../types/translation/Translator';
import { useNavigate } from 'react-router-dom';
import { ProductMasterDataContext } from '../../../context/ProductMasterDataContext';
import { ProductTransactionContext } from '../../../context/ProductTransactionContext';
import { UserContext } from '../../../context/UserContext';
import {
  ProductTransaction,
  ProductTransactionParentType,
  productTransactionParentTypeToString,
  ProductTransactionStatus,
  ProductTransactionType,
} from '../../../types/productTransaction';
import { Grid } from '@mui/material';
import { iconForType } from '../../../util/productTransactions.util';
import ProductTransactionDetailModal from '../Product/Modals/ProductTransactionDetailModal';
import { StockLocationContext } from '../../../context/StockLocationContext';
import { StockLocationRoleAssignmentContext } from '../../../context/StockLocationRoleAssignmentContext';
import { StockLocationRole } from '../../../types/stockLocationRoleAssignment';
import { quantityWithSuffix } from '../../../types/unitOfMeasure';
import { removeDiacritics, toFilterString } from '../../../util/string.util';
import { ProductTransactionExportModal } from './Modals/ProductTransactionExportModal';
import SearchBarWithFilter from '../../../VentoryUI/components/common/SearchBarWithFilter/SearchBarWithFilter';
import { OrderContext } from '../../../context/OrderContext';
import { MenuItemProps } from '../../../VentoryUI/components/common/Menu/MenuItem';
import ExportIcon from '../../../VentoryUI/icons/Export/ExportIcon';
import { SettingsMenuItemTemplate } from '../../../VentoryUI/components/common/Menu/Templates/SettingsMenuItem';
import { FlexPane } from '../../../VentoryUI/components/common/FlexPane/FlexPane';
import Table from '../../../VentoryUI/components/common/Table/Table';
import { SystemUser } from '../../../types/user';
import ImportIcon from '../../../VentoryUI/icons/Import/ImportIcon';
import { ProductTransactionFilter } from '../../../VentoryUI/components/filters/Filter/ProductTransactionFilter';
import { TagRelationContext } from '../../../context/TagRelationContext';
import { CompanyContext } from '../../../context/CompanyContext';

interface ProductHistoryOverviewPaneInputProps {}

export default function ProductHistoryOverviewPane({}: ProductHistoryOverviewPaneInputProps) {
  const navigate = useNavigate();

  const { currentCompany } = useContext(CompanyContext);
  const { productMasterData, productMasterDataLoading } = useContext(ProductMasterDataContext);
  const { orders, setOrders, ordersLoading, loadAllOrders } = useContext(OrderContext);
  const { productTransactions, setProductTransactions, productTransactionsLoading, loadAllProductTransactions } =
    useContext(ProductTransactionContext);
  const { companyUsers } = useContext(UserContext);
  const { filteredStockLocations } = useContext(StockLocationContext);
  const { hasStockLocationRole } = useContext(StockLocationRoleAssignmentContext);
  const { tagRelations } = useContext(TagRelationContext);

  const [openDetailModal, setOpenDetailModal] = useState<boolean>(false);
  const [selectedTransaction, setSelectedTransaction] = useState<ProductTransaction | null>(null);
  const [exportModalOpen, setExportModalOpen] = useState<boolean>(false);

  const items = useMemo(
    () =>
      [...productTransactions.values()]
        .filter(a => a.parentType !== ProductTransactionParentType.transaction)
        .sort(
          (a, b) => new Date(b.processedAt || b.createdAt).getTime() - new Date(a.processedAt || a.createdAt).getTime(),
        ),
    [productTransactions],
  );
  const [filteredItems, setFilteredItems] = useState<ProductTransaction[]>(items);

  const getElterosSpecificStockLocation = (item: ProductTransaction) => {
    if (item.status === ProductTransactionStatus.processed || item.status === ProductTransactionStatus.rollback) {
      if (item.type === ProductTransactionType.inbound) {
        return filteredStockLocations.get(item.product.toStockLocationId || '')?.name || '';
      } else if (item.type === ProductTransactionType.outbound) {
        if (item.parentType === ProductTransactionParentType.order && item.parentId) {
          const order = orders.get(item.parentId);
          if (order) {
            const projekt = order.customFields.get('08281bc5-cab0-4a89-a0fa-9327969e5f10');
            if (projekt) return projekt.value;
          }
        }

        return filteredStockLocations.get(item.product.fromStockLocationId || '')?.name || '';
      }
    }
    return '';
  };

  const headers = [
    {
      key: 'processedAt',
      name: t().processedAt.singular.label,
      text: (item: ProductTransaction) =>
        `${new Date(item.processedAt || item.createdAt).toLocaleDateString()} - ${new Date(
          item.processedAt || item.createdAt,
        ).toLocaleTimeString()}`,
      sortValue: (item: ProductTransaction) => item.processedAt || item.createdAt,
      defaultSort: true,
    },
    {
      key: 'processedBy',
      name: t().processedBy.singular.label,
      text: (item: ProductTransaction) => {
        if (!item.processedBy) return '';
        return (
          companyUsers.get(item.processedBy || '')?.email ||
          (item.processedBy === SystemUser.id ? SystemUser.email : 'Unknown user')
        );
      },
    },
    {
      key: 'productNumber',
      name: t().productNumber.singular.label,
      text: (item: ProductTransaction) =>
        productMasterData.get(item.product.pmdId || '')?.productNumber || 'Unknown Product Reference',
    },
    {
      key: 'action',
      name: t().actions.singular.label,
      text: (item: ProductTransaction) =>
        item.parentType === ProductTransactionParentType.order && item.parentId
          ? orders.get(item.parentId)?.number || 'Order'
          : productTransactionParentTypeToString(item.parentType),
    },
    {
      key: 'stockLocation',
      name:
        currentCompany.id === 'c8ab03f6-1770-4104-a2cb-560ceeca429d' ? 'Projektas' : t().stockLocation.singular.label,
      text: (item: ProductTransaction) => {
        // This is really dirty and should be fixed!!
        if (currentCompany.id === 'c8ab03f6-1770-4104-a2cb-560ceeca429d') {
          return getElterosSpecificStockLocation(item);
        }

        if (item.status === ProductTransactionStatus.processed || item.status === ProductTransactionStatus.rollback) {
          if (item.type === ProductTransactionType.inbound) {
            return filteredStockLocations.get(item.product.toStockLocationId || '')?.name || '';
          } else if (item.type === ProductTransactionType.outbound) {
            return filteredStockLocations.get(item.product.fromStockLocationId || '')?.name || '';
          }
        }
        return '';
      },
    },
    {
      key: 'quantity',
      name: t().quantity.singular.label,
      text: (item: ProductTransaction) => (
        <Grid container columnSpacing={1} justifyContent={'flex-end'}>
          <Grid item marginY={'auto'} className='h-7'>
            <p
              title={item.product.quantity.toString()}
              className={`h-7 text-ellipsis py-1 overflow-hidden text-sm whitespace-nowrap`}
            >
              {quantityWithSuffix(
                (item.product.processedQuantity || item.product.quantity).toString(),
                productMasterData.get(item.product.pmdId || '')?.unitOfMeasure,
              )}
            </p>
          </Grid>
          <Grid item marginY={'auto'}>
            {iconForType(item)}
          </Grid>
        </Grid>
      ),
      sortValue: (item: ProductTransaction) => item.product.processedQuantity || item.product.quantity,
      columnWidth: 0.5,
    },
  ];

  const handleFilter = (item: ProductTransaction, filter: string) => {
    if (
      filteredStockLocations.has(item.product.toStockLocationId || '') &&
      item.product.toStockLocationId &&
      !hasStockLocationRole(item.companyId, item.product.toStockLocationId, StockLocationRole.STOCK_LOCATION_MANAGER)
    ) {
      return false;
    }

    if (
      filteredStockLocations.has(item.product.fromStockLocationId || '') &&
      item.product.fromStockLocationId &&
      !hasStockLocationRole(item.companyId, item.product.fromStockLocationId, StockLocationRole.STOCK_LOCATION_MANAGER)
    ) {
      return false;
    }

    if (
      removeDiacritics(toFilterString(companyUsers.get(item.processedBy || '')?.email.toLowerCase())).includes(
        filter,
      ) ||
      removeDiacritics(
        toFilterString(productMasterData.get(item.product.pmdId || '')?.productNumber.toLowerCase()),
      ).includes(filter)
    ) {
      return true;
    }

    if (removeDiacritics(toFilterString(orders.get(item.parentId || '')?.number?.toLowerCase())).includes(filter)) {
      return true;
    }

    return false;
  };

  const loadAll = async () => {
    loadAllProductTransactions();
    loadAllOrders();
  };

  const menuItems: MenuItemProps[] = useMemo(() => {
    return [
      {
        text: t().export.singular.label,
        icon: <ExportIcon />,
        onClick: () => setExportModalOpen(true),
      },
      SettingsMenuItemTemplate(() => navigate('settings/report')),
      {
        text: t().loadAll.singular.label,
        icon: <ImportIcon />,
        onClick: loadAll,
      },
    ];
  }, []);

  return (
    <>
      <ProductTransactionExportModal
        transactionIds={new Set(filteredItems.map(item => item.id))}
        open={exportModalOpen}
        setOpen={setExportModalOpen}
      />
      {selectedTransaction ? (
        <ProductTransactionDetailModal
          open={openDetailModal}
          setOpen={v => {
            setOpenDetailModal(v);
            setSelectedTransaction(null);
          }}
          transaction={selectedTransaction}
        />
      ) : null}

      <FlexPane
        header={
          <SearchBarWithFilter
            loading={productMasterDataLoading || productTransactionsLoading}
            items={items}
            setItems={setFilteredItems}
            placeholder={t().filterProductTransactions.singular.label}
            menuItems={menuItems}
            filter={new ProductTransactionFilter(companyUsers, productMasterData, orders, tagRelations)}
          />
        }
        content={
          <Table
            loading={productMasterDataLoading || ordersLoading || productTransactionsLoading}
            items={filteredItems}
            headers={headers}
            totalItemCount={items.length}
            onClick={item => {
              setSelectedTransaction(item);
              setOpenDetailModal(true);
            }}
          />
        }
      />
    </>
  );
}
