import React, { useContext, useState } from 'react';
import { CompanyContext } from '../../../context/CompanyContext';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { CustomerQueries, GetCustomersResponse, GetCustomersVariables } from '../../../graphql/customer.graphql';
import { Company } from '../../../types/company';
import { toMap } from '../../../util/map.util';
import CompanySelectFrame from '../../Common/CompanySelectFrame';
import SearchBar from '../../../VentoryUI/components/common/SearchBar/SearchBar';
import Paper from '../../../VentoryUI/components/common/Paper/Paper';
import { FlexPane } from '../../../VentoryUI/components/common/FlexPane/FlexPane';
import { VentorySubscriptionState, VentorySubscriptionType } from '../../../types/billing';
import LoadingPackage from '../../Common/LoadingPackage';
import { removeDiacritics, toFilterString } from '../../../util/string.util';

export default function AdministratorCustomerPane() {
  const navigate = useNavigate();
  const { refreshCompany } = useContext(CompanyContext);

  const [customers, setCustomers] = useState<Map<string, Company>>(new Map());
  const [filter, setFilter] = useState<string>('');

  const [activeCustomer, setActiveCustomer] = useState<Company[]>([]);
  const [partnerCustomer, setPartnerCustomer] = useState<Company[]>([]);
  const [trialCustomer, setTrialCustomer] = useState<Company[]>([]);
  const [demoCustomer, setDemoCustomer] = useState<Company[]>([]);

  const [expiredTrialCustomer, setExpiredTrialCustomer] = useState<Company[]>([]);

  const [otherCustomer, setOtherCustomer] = useState<Company[]>([]);

  const { loading } = useQuery<GetCustomersResponse, GetCustomersVariables>(CustomerQueries.get, {
    onCompleted: res => {
      setCustomers(new Map(toMap(res.customers.data, 'id')));

      setActiveCustomer([]);
      setPartnerCustomer([]);
      setTrialCustomer([]);

      setExpiredTrialCustomer([]);

      setOtherCustomer([]);

      setDemoCustomer([]);

      res.customers.data.forEach(customer => {
        console.log(customer.name, customer.id, customer.status);
        if (customer.status?.state === VentorySubscriptionState.active) {
          switch (customer.status.type) {
            case VentorySubscriptionType.custom:
              activeCustomer.push(customer);
              return;
            case VentorySubscriptionType.poc:
              partnerCustomer.push(customer);
              return;
            case VentorySubscriptionType.trial:
              trialCustomer.push(customer);
              return;
            case VentorySubscriptionType.demo:
              demoCustomer.push(customer);
              return;
          }
        } else if (customer.status?.state === VentorySubscriptionState.expired) {
          switch (customer.status.type) {
            case VentorySubscriptionType.trial:
              expiredTrialCustomer.push(customer);
              return;
          }
        }

        otherCustomer.push(customer);
      });

      setActiveCustomer(activeCustomer);
      setPartnerCustomer(partnerCustomer);
      setTrialCustomer(trialCustomer);
      setDemoCustomer(demoCustomer);

      setExpiredTrialCustomer(expiredTrialCustomer);

      setOtherCustomer(otherCustomer);
    },
  });

  const display = (items: Company[]) => {
    return items.map(item => (
      <Grid item>
        <CompanySelectFrame
          key={item.id}
          showBorderColor={true}
          company={item}
          onClick={async () => {
            await refreshCompany(item.id);
            navigate('/dashboard');
          }}
        />
      </Grid>
    ));
  };

  return (
    <FlexPane
      wrapContent={false}
      header={
        <Grid container>
          <Grid item flexGrow={1}>
            <SearchBar placeholder='Filter Customers' onChange={v => setFilter(v)} />
          </Grid>
        </Grid>
      }
      content={
        <Paper overflow='auto'>
          {loading ? (
            <Grid item xs={12} height={'100%'} justifyItems={'center'} alignContent={'center'}>
              <LoadingPackage />
            </Grid>
          ) : (
            <Grid container rowSpacing={1}>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <p className='font-bold p-1'>{`Active customers (${activeCustomer.length})`}</p>
                  </Grid>
                  <Grid item xs={12} flexDirection={'column'}>
                    <Grid container>
                      {display(activeCustomer.filter(c => removeDiacritics(toFilterString(c.name)).includes(filter)))}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <p className='font-bold p-1'>{`Trial customers (${trialCustomer.length})`}</p>
                  </Grid>
                  <Grid item xs={12} flexDirection={'column'}>
                    <Grid container>
                      {display(trialCustomer.filter(c => removeDiacritics(toFilterString(c.name)).includes(filter)))}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <p className='font-bold p-1'>{`Partner customers (${partnerCustomer.length})`}</p>
                  </Grid>
                  <Grid item xs={12} flexDirection={'column'}>
                    <Grid container>
                      {display(partnerCustomer.filter(c => removeDiacritics(toFilterString(c.name)).includes(filter)))}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <p className='font-bold p-1'>{'Trial customers (expired)'}</p>
                  </Grid>
                  <Grid item xs={12} flexDirection={'column'}>
                    <Grid container>
                      {display(
                        expiredTrialCustomer.filter(c => removeDiacritics(toFilterString(c.name)).includes(filter)),
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <p className='font-bold p-1'>{'Archived'}</p>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      {display(otherCustomer.filter(c => removeDiacritics(toFilterString(c.name)).includes(filter)))}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <p className='font-bold p-1'>{'Demo customers'}</p>
                  </Grid>
                  <Grid item xs={12} flexDirection={'column'}>
                    <Grid container>
                      {display(demoCustomer.filter(c => removeDiacritics(toFilterString(c.name)).includes(filter)))}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Paper>
      }
    />
  );
}
