import React, { useContext, useState } from 'react';
import { getBorderColor } from '../../util/color.util';
import { CompanyContext } from '../../context/CompanyContext';
import { Colors, ColorStyleType } from '../../VentoryUI/util/color.util';
import Intercom from '@intercom/messenger-js-sdk';
import { UserContext } from '../../context/UserContext';
import dayjs from 'dayjs';
import { t } from '../../types/translation/Translator';

export default function IntercomBadge() {
  const { currentUser } = useContext(UserContext);
  const { currentCompany } = useContext(CompanyContext);

  const [open, setOpen] = useState<boolean>(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const badge = document.getElementById('intercom_badge')?.getBoundingClientRect();

  Intercom({
    app_id: process.env.REACT_APP_INTERCOM_APP_ID || '',
    user_id: currentUser?.userId,
    name: `${currentUser?.firstName} ${currentUser?.lastName}`,
    email: currentUser?.email,
    created_at: currentUser?.createdAt ? dayjs(currentUser.createdAt).unix() : dayjs().unix(),
    background_color: currentCompany.settings.primaryColor,
    action_color: currentCompany.settings.primaryColor,
    custom_launcher_selector: '#intercom_badge',
    hide_default_launcher: true,
    language_override: currentUser?.language || 'en',
    vertical_padding: window.innerHeight - (badge?.top || 0) - (badge?.height || 0),
    horizontal_padding: 35,
    user_hash: currentUser?.supportHMAC,
  });

  return (
    <span
      id='intercom_badge'
      onClick={handleClick}
      style={{
        backgroundColor: Colors.getBackgroundColor(currentCompany, 'secondary'),
        borderColor: getBorderColor(currentCompany, ColorStyleType.primary),
        color: Colors.getTextColor(currentCompany, 'secondary'),
      }}
      className='rounded-xl text-sm cursor-pointer select-none'
    >
      <p>{t().support.singular.label}</p>
    </span>
  );
}
